import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

// from https://blog.hackages.io/our-solution-to-get-a-previous-route-with-angular-5-601c16621cf0

@Injectable({
  providedIn: 'root',
})
export class NavHistoryService {
  private history: string[] = [];

  constructor(
    private router: Router,
  ) { }

  public loadRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe({
        next: (event) => {
          if (event.urlAfterRedirects !== this.history[this.history.length]) {
            this.history = [...this.history, event.urlAfterRedirects];
          }

          this.scrollToTop();
        },
      });
  }

  public getPreviousUrl(fallback?: string): string | null {
    return this.history[this.history.length - 2] || (fallback || null);
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
    // // https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    // const scrollToTop = window.setInterval(() => {
    //   const pos = window.pageYOffset;
    //   if (pos > 0) {
    //     window.scrollTo(0, pos - 20); // how far to scroll on each step
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 16);
  }
}
