import { Component } from '@angular/core';
import { getCountries } from '@dep/common/countries';
import { NgOption } from '@ng-select/ng-select';
import { NGXLogger } from 'ngx-logger';

import { ContactModel } from '@dep/frontend/models/contact';
import { AppsyncService } from '@dep/frontend/services/appsync.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-new-contact-popup',
  templateUrl: './new-contact-popup.component.html',
  styleUrls: ['./new-contact-popup.component.scss'],
})
export class PopupNewContactComponent {
  public model: ContactModel = this.resetModel();
  public formDisabled = false;

  public selectCountries: NgOption[] = [];
  public selectedCountry?: string | null;
  public popup?: IPopup;

  constructor(
    private logger: NGXLogger,
    private appsyncService: AppsyncService,
    private popupService: PopupService,
  ) {
    this.resetModel();

    const countriesCodes = Object.entries(getCountries());
    this.selectCountries = countriesCodes.map((c) => ({
      value: c[0],
      label: c[1],
    }));
  }

  private resetModel(): ContactModel {
    this.model = new ContactModel(-1, '', '', '', '', '', '-', '', '', '', '', this.appsyncService.generateRandomString(10));
    this.selectedCountry = null;
    return this.model;
  }

  public saveForm(): void {
    if (this.popup?.callbacks.onSave && typeof this.popup.callbacks.onSave === 'function') {
      this.logger.debug('ShopNewContactPopupComponent: Popup\'s save callback fired');

      this.formDisabled = true;
      this.popup.callbacks.onSave(this.model, this.popup.uuid);
      this.formDisabled = false;
    }
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  public selectedCountryChanged(): void {
    this.model.country = String(this.selectedCountry);
  }
}
