import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseCommonEntity } from '@dep/common/interfaces/model-record.interface';
import { ShopLevel } from '@dep/common/shop-api/enums/shop.enum';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, Observable, Subject } from 'rxjs';

import { ShopUserService } from './shop-user.service';
import { Shop } from '../models/shop.model';
import { IAPIRecordShop, IShopComponent } from '../tmp-utilities/shop-api/interfaces/shop.interface';

import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShopsService {
  public shopUpdated$: Subject<Shop>;
  private currentShop?: Shop;
  private shops?: Shop[];
  private shopsById: Map<number, Observable<IAPIRecordShop>> = new Map<number, Observable<IAPIRecordShop>>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private shopUserService: ShopUserService,
    private logger: NGXLogger,
  ) {
    this.shopUpdated$ = new Subject<Shop>();
  }

  public async create(locales: string[], components: IShopComponent[]): Promise<IBaseCommonEntity> {
    const orgaentityId = await this.shopUserService.getOrgaentityId();
    const userId = await this.shopUserService.getUserId();
    if (orgaentityId === null || userId === null) {
      this.logger.error('ShopsService: Cannot create shop, no orgaentity or user', orgaentityId, userId);
      throw new Error('Cannot create shop, no orgaentity or user');
    }

    const payload = {
      level: (await this.shopUserService.getShopLevel()),
      locales,
      components,
      orgaentityId,
      userId,
    };

    this.logger.debug('ShopsService: Create Shop', payload);

    const response = await lastValueFrom(this.http.post<IAPIRecordShop>(
      environment.config.shop.apiGateway.url + '/shops',
      payload,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.clear();
    this.shopUpdated$.next(new Shop(response));
    this.logger.debug('ShopsService: Created Shop response', response);
    return response;
  }

  public async update(locales: string[], components: IShopComponent[]): Promise<IBaseCommonEntity> {
    if (!this.currentShop) {
      this.logger.error('ShopsService: Cannot update shop, no shop set', this.currentShop);
      throw new Error('Cannot update shop, no shop set');
    }

    const payload = {
      level: this.currentShop.level,
      locales,
      components,
      orgaentityId: this.currentShop.orgaentityId,
      userId: await this.shopUserService.getUserId(),
    };

    this.logger.debug(
      'ShopsService: Update shop with ID',
      this.currentShop.id,
      payload,
    );

    const response = await lastValueFrom(this.http.put<IAPIRecordShop>(
      environment.config.shop.apiGateway.url + '/shops/' + this.currentShop.id,
      payload,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.clear();
    this.shopUpdated$.next(new Shop(response));
    this.logger.debug('ShopsService: Updated Shop response:', response);
    return response;
  }

  public async getShop(): Promise<Shop | undefined> {
    this.logger.debug('ShopsService: Getting own shop');
    if (this.currentShop) {
      return this.currentShop;
    }

    const shopsResponse = await this.listShops();
    if (!shopsResponse) {
      return undefined;
    }

    const shopLevel = await this.shopUserService.getShopLevel();
    this.logger.debug('ShopsService: Own shop level', shopLevel);
    const foundShop = this.shops?.find((shop) => shop.level === shopLevel?.valueOf());
    this.currentShop = foundShop || undefined;
    this.logger.debug('ShopsService: Got own shop', this.currentShop);
    return this.currentShop;
  }

  /**
   * Get shop by ID. Uses the list (`shops`) and single-item (`shopsById`) cache.
   *
   * @param id - Shop ID to be fetched
   */
  public async getShopById(id: number): Promise<Shop | undefined> {
    this.logger.debug('ShopsService: Getting shop by ID', id);

    // First, check the list cache.
    const cachedShop = this.shops?.find((shop) => shop.id === id);
    if (cachedShop) {
      return cachedShop;
    }

    // Second, check the single-item cache.
    let getShopSubscription: Observable<IAPIRecordShop>;
    const cacheValue = this.shopsById.get(id);
    if (cacheValue) {
      getShopSubscription = cacheValue;
    } else {
      // No cache hit, fetch shop from API.
      getShopSubscription = this.http.get<IAPIRecordShop>(
        `${environment.config.shop.apiGateway.url}/shops/${id}`,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      );
    }

    // Add shop to single-item cache.
    this.shopsById.set(id, getShopSubscription);

    const response = await lastValueFrom(getShopSubscription);
    this.logger.debug('ShopsService: Got shop', response);

    return new Shop(response);
  }

  public async listShops(): Promise<Shop[] | undefined> {
    this.logger.debug('ShopsService: listing shops');
    if (this.shops) {
      return this.shops;
    }
    const response = await lastValueFrom(this.http.get<IAPIRecordShop[]>(
      environment.config.shop.apiGateway.url + `/users/${await this.shopUserService.getUserId() || ''}/shops`,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('ShopsService: list shops');
    console.table(response);
    this.shops = response.map((shop) => new Shop(shop));
    return this.shops;
  }

  public async getShopByLevel(level: ShopLevel): Promise<Shop | null> {
    const shops = await this.listShops();
    const shop = shops?.find((currentShop) => currentShop.level === level.valueOf());
    return shop ?? null;
  }

  /**
   * Clear cache variables.
   */
  public clear(): void {
    this.currentShop = undefined;
    this.shops = undefined;
    this.shopsById.clear();
  }
}
