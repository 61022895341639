import { ModelAbstract } from '../abstracts/model.abstract';

import { IModelUser } from '@dep/frontend/shop/tmp-utilities/core-api/interfaces/user.interface';
import {
  IAPIRecordProduct,
  IModelProduct,
  IProductNotification,
  IProductAbstract,
  IProductComponent,
  IProductIntegration,
  IProductSettings,
} from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/product.interface';
import { IModelShop } from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/shop.interface';

export class ProductModel extends ModelAbstract implements IModelProduct {
  public settings: IProductSettings;
  public notifications: IProductNotification[];
  public abstract: IProductAbstract;
  public components: IProductComponent[];
  public integrations: IProductIntegration[];
  public derivedFromId?: number;
  public derivedFromVersion?: number;
  public shopId: number;
  public userId: number;
  public shop?: IModelShop | undefined;
  public user?: IModelUser | undefined;
  public version: number;
  public identifier?: string;

  constructor(data: IAPIRecordProduct) {
    super(data.id, data.created ? new Date(data.created) : new Date(), data.updated ? new Date(data.updated) : new Date());

    this.version = data.version;
    this.settings = data.settings;
    this.notifications = data.notifications;
    this.abstract = data.abstract;
    this.components = data.components;
    this.integrations = data.integrations;
    this.derivedFromId = data.derivedFromId;
    this.derivedFromVersion = data.derivedFromVersion;
    this.shopId = data.shopId;
    this.userId = data.userId;
    this.identifier = data.identifier;
  }

  public isRenewsProduct(): boolean {
    return this.settings && this.settings.renewsProduct;
  }

  public removeComponent(componentId: string): void {
    this.components = this.components.filter((component) => component.id !== componentId);
  }
}
