import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { orgaentityBase } from '../../core-api/types/orgaentity.type';
extendZodWithOpenApi(z);
export const ordersBatchInput = z.object({
    dryRun: z.boolean(),
    shopProductId: z.number().positive(),
    orgaentityIds: z.array(z.number().positive()),
    batchSize: z.number().positive(),
    batchAlreadyProcessedPaths: z.array(orgaentityBase.shape.path),
});
