import { Injectable } from '@angular/core';
import { getCountries } from '@dep/common/countries';
import { IContact } from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';

import { BasketCookie } from '../tmp-utilities/shop-api/interfaces/basket.interface';
import { IOrderProduct } from '../tmp-utilities/shop-api/interfaces/order.interface';
import { IProductComponentProduct } from '../tmp-utilities/shop-api/interfaces/product.interface';

import { UserService } from '@dep/frontend/services/user.service';
import { ProductModel } from '@dep/frontend/shop/models/product.model';
import { ProductsService } from '@dep/frontend/shop/services/products.service';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  public product?: ProductModel;
  public orderProducts: Partial<IOrderProduct>[] = [];
  public extraData?: any;
  public hideLocations?: boolean;
  public basketMetadata: { shopOEName?: string; shopPath?: string; } = {};

  constructor(
    private logger: NGXLogger,
    private userService: UserService,
    public productsService: ProductsService,
  ) {
    const basketCookie = localStorage.getItem('shop/basket');

    if (basketCookie && basketCookie.length > 0) {
      const basketCookieContent = JSON.parse(basketCookie) as BasketCookie;
      // Only load old basket if not older than 24 hours.
      if (basketCookieContent.lastModified + 24 * 60 * 60 * 1000 > new Date().getTime()) {
        this.logger.debug(`BasketService: Restored basket contents, valid next
        ${(((basketCookieContent.lastModified + 24 * 60 * 60 * 1000) - new Date().getTime()) / 1000)} seconds`);
        this.product = new ProductModel(basketCookieContent.product);
        this.orderProducts = basketCookieContent.orderProducts;
        this.extraData = basketCookieContent.extraData;
        this.hideLocations = basketCookieContent.hideLocations;
        this.basketMetadata = basketCookieContent.basketMetadata;
      }
    }

    // Clear basket on logout.
    this.userService.sessionObservable$.subscribe({
      next: (cognitoUser) => {
        if (!cognitoUser) {
          this.clearBasket();
        }
      },
    });
  }

  public clearBasket(): void {
    this.product = undefined;
    this.orderProducts = [];
    this.extraData = undefined;
    this.hideLocations = undefined;
    this.basketMetadata = {};
    localStorage.removeItem('shop/basket');
    this.logger.debug('BasketService: Cleared basket');
  }

  public removeProduct(componentProduct: IProductComponentProduct): void {
    this.logger.debug('BasketService: Removing product from basket', componentProduct);

    this.orderProducts = this.getOrderProducts().filter((order) => order.productName !== componentProduct.product.name);
    this.persistBasket();

    this.logger.debug('BasketService: Removed component product with id', componentProduct.id);
  }

  public addOrderProduct(orderProduct: Partial<IOrderProduct>): void {
    this.orderProducts.push(orderProduct);
    this.persistBasket();

    this.logger.debug('BasketService: Added new orderProduct to basket', orderProduct, this.orderProducts);
  }

  public persistBasket(): void {
    this.logger.debug('BasketService: Persisting basket', this.orderProducts.length);

    if (!this.product || this.orderProducts.length === 0) {
      this.logger.debug('BasketService: Removing basket cookie');
      localStorage.removeItem('shop/basket');
    } else {
      this.logger.debug('BasketService: Persisting basket now', this.orderProducts, this.extraData, this.hideLocations, this.basketMetadata);
      localStorage.setItem('shop/basket', JSON.stringify({
        product: this.product,
        orderProducts: this.orderProducts,
        extraData: this.extraData,
        hideLocations: this.hideLocations,
        basketMetadata: this.basketMetadata,
        lastModified: new Date().getTime(),
      }));
    }
  }

  public setProduct(product: ProductModel): void {
    this.product = product;
    this.logger.debug('BasketService: Basket product is set.');
  }

  public getProduct(): ProductModel | undefined {
    return this.product;
  }

  public getOrderProducts(): Partial<IOrderProduct>[] {
    return this.orderProducts;
  }

  /**
   * Get shipping contact from local storage extradata.location.address
   * @returns shippingContact as IContact or null
   */
  public getShippingContact(): IContact | null {
    let shippingContact = {
      id: Math.random(),
      name: '',
      street: '',
      zip: '',
      city: '',
      country: '',
    };

    if (this.extraData && this.extraData.location && this.extraData.location.isNew) { // New location
      shippingContact.name = this.extraData.location.name;
      shippingContact.street = this.extraData.location.street;
      shippingContact.zip = this.extraData.location.zip;
      shippingContact.city = this.extraData.location.city;
      shippingContact.country = this.extraData.location.country;
    } else if (this.extraData && this.extraData.location && this.extraData.location.address) { // address form selected gssn
      shippingContact = this.extraData.location.address as IContact;
    }

    // check for complete address
    if (
      !shippingContact.name
      || !shippingContact.street
      || !shippingContact.zip
      || !shippingContact.city
      || !shippingContact.country
    ) {
      this.logger.error('BasketService: Could not find shipping contact.');
      return null;
    }

    const countriesCodes = Object.entries(getCountries());
    let countryCode = countriesCodes.find((c) => c[0] === shippingContact.country);
    if (!countryCode) {
      // If the shipping contact came from node (e. g. CA), it contains the
      // country name instead of the country code.
      countryCode = countriesCodes.find((c) => c[1] === shippingContact.country);
    }
    if (!countryCode) {
      throw new Error('Shipping address country not found in countries codes list');
    }

    [shippingContact.country] = countryCode;
    this.logger.debug('BasketService: Found shipping address in extraData.', shippingContact);
    return shippingContact as IContact;
  }

  // @Jonas: Please check, do we need fallback?
  public getMinMaxAmount(p: IProductComponentProduct) : number[] {
    const amount = [];
    // fallback 1 and 10
    const start = p.product.price.modes.QUANTITY_MINIMUM || 1;
    const end = p.product.price.modes.QUANTITY_MAXIMUM || 10;
    for (let i = start; i <= end; ++i) {
      amount.push(Math.floor(i));
    }

    return amount;
  }

  // TODO: Add check for modes with PRODUCT_DEPENDENT_LEVELS (CA products)
  /**
   * On amount change calculates and sets totalPriceNet and quantity in orderProducts
   * If the current product has depended product (i.e. listed in PER_PRODUCT in the modes of a depended product)
   * then also set the quantity and price for the depended product
   * @param index index of the current product
   * @param amount amount of the current product
   * @param productComponentProducts all sub-product components of a product
   * @param orderProducts list of orderProducts
   */
  public onProductAmountChange(
    index: number,
    amount: number,
    productComponentProducts: IProductComponentProduct[],
    orderProducts: Partial<IOrderProduct>[],
  ): void {
    this.updatePriceOnOrderProduct(orderProducts[index], productComponentProducts[index], amount);

    this.logger.debug('BasketService: Updating depended products quantity');
    for (const p of productComponentProducts) {
      const foundIndex = orderProducts.findIndex((item) => item.productName === (p.product.name));
      // CASE: PER_PRODUCT
      if (p.product.price.modes && p.product.price.modes.PER_PRODUCT) {
        // Find the depended product.
        if (p.product.price.modes.PER_PRODUCT === productComponentProducts[index].product.name) {
          this.logger.debug('BasketService: Found depended product', p);
          // Set quantity and total price in orderProducts.
          if (foundIndex > -1) {
            orderProducts[foundIndex].quantity = amount;
            if (p.product.price.modes.PER_MONTH_UNTIL) {
              this.updatePriceOnOrderProduct(orderProducts[foundIndex], productComponentProducts[foundIndex], amount);
            }
          }
        }
      }
      // CASE: GRADUATED_PRICE
      if (p.product.price.modes.GRADUATED_PRICE) {
        const quantity = this.productsService.determineOrderProductsQuantity(p, orderProducts);
        const graduatedPrice = this.productsService.determineGraduatedPrice(quantity, p);
        const productTaxRate = p.product.price.taxRate ?? 0;

        // Set quantity and total price in orderProducts.
        orderProducts[foundIndex].totalPriceNet = graduatedPrice;
        orderProducts[foundIndex].totalPriceGross = Math.round(graduatedPrice * (1 + productTaxRate));
        orderProducts[foundIndex].quantity = quantity ? 1 : 0;
      }
      // TODO
      // CASE: PRODUCT_DEPENDENT_LEVELS
    }
  }

  /**
   * Update `totalPriceNet` and `totalPriceGross` on `orderProduct`.
   *
   * @param orderProduct Order product that will be updated
   * @param productComponentProduct Product component used to determine price, price mode, etc.
   * @param amount Quantity of the product component in the order/basket
   */
  private updatePriceOnOrderProduct(
    orderProduct: Partial<IOrderProduct>,
    productComponentProduct: IProductComponentProduct,
    amount: number,
  ): void {
    // Set net price.
    orderProduct.totalPriceNet = Math.floor(amount * productComponentProduct.product.price.net);
    if (productComponentProduct.product.price.modes.PER_MONTH_UNTIL) {
      // Set net price for monthly paid product (PER_MONTH_UNTIL).
      orderProduct.totalPriceNet = this.calculatePeriodInMonthsPrice(productComponentProduct) * amount;
    }

    // Set gross price.
    const productTaxRate = productComponentProduct.product.price.taxRate ?? 0;
    const productTotalPriceNet = orderProduct.totalPriceNet;
    orderProduct.totalPriceGross = Math.round(productTotalPriceNet * (1 + productTaxRate));
    this.logger.debug(
      'BasketService: Updating total gross price:',
      `${Number(orderProduct.totalPriceNet)} * ${(1 + productTaxRate)} = ${Number(orderProduct.totalPriceGross)}`,
    );
  }

  public calculatePeriodInMonthsPrice(componentProduct: IProductComponentProduct): number {
    if (!componentProduct.product.price.modes.PER_MONTH_UNTIL) {
      return componentProduct.product.price.net;
    }
    const activationDate = this.productsService.getActivationDate();
    const months = this.productsService.getLicensePeriodInMonths(componentProduct.product.price.modes.PER_MONTH_UNTIL, activationDate);
    return componentProduct.product.price.net * months;
  }

  /**
   * Get the total amount of products (excl. shipping) in basket.
   */
  public getAbsoluteProductCount(): number {
    return this.orderProducts.reduce((total, product) => Number(total) + Number(product.quantity), 0);
  }
}
