import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { type ListResponse } from '@dep/common/interfaces';
import {
  type OrdersBatchInput,
  ordersBatchInput,
  type OrdersBatchOutput,
} from '@dep/common/shop-api/types/orders-batch.type';
import { NGXLogger } from 'ngx-logger';
import { Subject, lastValueFrom } from 'rxjs';

import { ApiService } from './api.service';
import {
  type IAPIOrderCreateInput,
  type IAPIRecordOrder,
  type ICriteriaList,
  type IOrderExportDownload,
} from '../tmp-utilities/shop-api/interfaces/order.interface';
import { type IStateMachineOutput } from '../tmp-utilities/shop-api/interfaces/state-machine.interface';

import { UserService } from '@dep/frontend/services/user.service';
import { OrderModel } from '@dep/frontend/shop/models/order.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  /** Subject that is triggered whenever the orders indicators state should be rechecked. */
  public ordersIndicatorChanged$ = new Subject<void>();

  constructor(
    private logger: NGXLogger,
    private apiService: ApiService,
    private http: HttpClient,
    private userService: UserService,
  ) { }

  public async getOrders(params: Record<string, any>): Promise<ListResponse<OrderModel>> {
    this.logger.debug('OrdersService: Getting orders from API', params);
    const apiOrders = await this.apiService.get<IAPIRecordOrder[]>('/orders', params);
    this.logger.debug('OrdersService: Got orders from API');
    console.table(apiOrders);

    const orders: ListResponse<OrderModel> = {
      items: apiOrders.map((apiOrder) => new OrderModel(apiOrder)),
      count: undefined,
    };

    this.logger.debug('OrdersService: Response from API (orders)', orders);
    return orders;
  }

  public async getOrderById(id: number): Promise<OrderModel> {
    this.logger.debug('OrdersService: Getting order by ID', id);

    const apiOrder = await this.apiService.get<IAPIRecordOrder>(`/orders/${id}?deepResolveDepth=2`);
    this.logger.debug(`OrdersService: Got order by ID: ${id}`, apiOrder);

    return new OrderModel(apiOrder);
  }

  public async createOrder(orderCreateInput: IAPIOrderCreateInput): Promise<{ stateMachineName: string }> {
    this.logger.debug('OrdersService: Create Order', orderCreateInput);

    const response = await lastValueFrom(this.http.post<{ stateMachineName: string }>(
      environment.config.shop.apiGateway.url + '/orders',
      orderCreateInput,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));

    this.logger.debug('OrdersService: Create Order response', response);
    return response;
  }

  public async createBatchOrders(input: OrdersBatchInput): Promise<OrdersBatchOutput> {
    this.logger.debug('OrdersService: Create batch orders', input);

    const response = await lastValueFrom(this.http.post<OrdersBatchOutput>(
      environment.config.shop.apiGateway.url + '/orders/batch',
      ordersBatchInput.parse(input),
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));

    this.logger.debug('OrdersService: Create batch orders response', response);
    return response;
  }

  public async getCreationOrderState(executionId: string): Promise<IStateMachineOutput> {
    this.logger.debug('OrdersService: Getting state of order creation process with executionId', executionId);

    const states = await this.apiService.get<any>(`/orders/states/${executionId}`);
    this.logger.debug('OrdersService: Got state of order creation proces:', states);

    return states;
  }

  public async updateOrder(orderId: number, orderUpdateInput: IAPIOrderCreateInput): Promise<{ stateMachineName: string }> {
    this.logger.debug('OrdersService: Updating order', orderUpdateInput);

    const response = await lastValueFrom(this.http.put<{ stateMachineName: string }>(
      environment.config.shop.apiGateway.url + '/orders/' + orderId,
      orderUpdateInput,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));

    this.logger.debug('OrdersService: Updated order response', response);
    return response;
  }

  public async getUpdateOrderState(executionId: string, orderId: number): Promise<IStateMachineOutput> {
    this.logger.debug('OrdersService: Getting state of order update process with executionId', executionId);

    const states = await this.apiService.get<IStateMachineOutput>(`/orders/${orderId}/states/${executionId}`);
    this.logger.debug('OrdersService: Got state of order update proces:', states);

    return states;
  }

  public async exportOrders(format: string, filter: ICriteriaList): Promise<any> {
    this.logger.debug(`OrdersService: exporting orders with format ${format} and filters Input`, filter);
    const response = await this.apiService.post<any>(
      '/orders/exports',
      {
        format,
        filter,
      },
    );
    this.logger.debug('OrdersService: exported orders with response:', response);
    return response;
  }

  public async getExportOrdersDownloadUrl(exportId: string): Promise<IOrderExportDownload> {
    this.logger.debug(`OrdersService: getting export orders download URL for exportId ${exportId}`);
    const response = await this.apiService.get<IOrderExportDownload>('/orders/exports/' + exportId);
    this.logger.debug('OrdersService: got export orders download URL with response:', response);
    return response;
  }

  public refreshOrdersIndicator(): void {
    this.ordersIndicatorChanged$.next();
  }
}
