/**
 * Types for product components.
 * FIXME: @Jonas: implement annotation for each type
 */
var ProductComponentTypeEnum;
(function (ProductComponentTypeEnum) {
    ProductComponentTypeEnum["ABSTRACT"] = "ABSTRACT";
    ProductComponentTypeEnum["INTRODUCTION"] = "INTRODUCTION";
    ProductComponentTypeEnum["PRODUCT"] = "PRODUCT";
})(ProductComponentTypeEnum || (ProductComponentTypeEnum = {}));
export { ProductComponentTypeEnum as ProductComponentType };
