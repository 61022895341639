import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShopCacheService {
  private readonly CACHE_DURATION_IN_SECONDS = 10;

  private cache: {
    [key: string]: {
      expires: Date,
      value: Observable<any>
    } | undefined;
  } = {};

  constructor(
    private logger: NGXLogger,
  ) { }

  /**
   * Get a value from the cache.
   *
   * @param requestObject Request object that will be used to generated the cache hash key
   * @returns Cached item (if found)
   */
  public getValue<T>(requestObject?: any): Observable<T> | null {
    this.logger.debug('ShopCacheService: Getting value', requestObject);
    const key = this.generateHashCyrb53(JSON.stringify(requestObject)).toString();
    const item = this.cache[key];
    if (!item) {
      // Item not found in cache.
      return null;
    }

    if (new Date() > item.expires) {
      // Item found but has expired.
      return null;
    }

    return item.value;
  }

  /**
   * Insert or replace a value in the cache.
   *
   * @param requestObject Request object that will be used to generated the cache hash key
   * @param value Item content that will be cached
   */
  public setValue<T>(requestObject: any, value: Observable<T>): void {
    this.logger.debug('ShopCacheService: Setting value', requestObject);
    const key = this.generateHashCyrb53(JSON.stringify(requestObject)).toString();
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + this.CACHE_DURATION_IN_SECONDS);
    this.cache[key] = { expires, value };
  }

  /**
   * Remove all items from the cache.
   */
  public clearCache(): void {
    this.cache = {};
  }

  /**
   * Generate hash that will be used as the cache's key.
   *
   * Source: {@link https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js} (bryc, public domain)
   * Related: {@link https://stackoverflow.com/a/52171480}
   *
   * @source
   * @param input String to generate the hash value from
   * @param seed Seed
   * @returns Hash value
   */
  private generateHashCyrb53(input: string, seed = 0): number {
    if (!input) {
      this.logger.error('ShopCacheService: Empty input for generating the hash', input);
    }

    // Need to disable no-bitwise rule because these operators are required for the hash generation.
    /* eslint-disable no-bitwise */
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < input.length; i++) {
      ch = input.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      h1 = Math.imul(h1 ^ ch, 2654435761);
      // eslint-disable-next-line no-bitwise
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    /* eslint-enable no-bitwise */
  }
}
