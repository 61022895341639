import { ModelAbstract } from '../abstracts/model.abstract';
import { ShopComponentName } from '../tmp-utilities/shop-api/enums/shop-component.enum';

import {
  IAPIRecordShop, IModelShop,
  IShopComponent, IShopHistory,
} from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/shop.interface';

class ShopModel extends ModelAbstract implements IModelShop {
  // members
  public components: IShopComponent[];
  public level: number;
  public locales: string[];
  public history: IShopHistory[];

  // references
  public orgaentityId: number;
  public userId: number;

  constructor(data: IAPIRecordShop) {
    super(data.id, new Date(data.created), new Date(data.updated));
    this.components = data.components;
    this.locales = data.locales;
    this.orgaentityId = data.orgaentityId;
    this.userId = data.userId;
    this.level = data.level;
    this.history = data.history;
  }

  public getShopComponentValue(componentName: ShopComponentName): { [key: string]: string } {
    const foundComponent = this.components.find((component) => component.name === componentName);
    if (foundComponent && foundComponent.text) {
      return foundComponent.text;
    }
    return {};
  }

  public updateLocales(selectedLocales: string[]): void {
    this.locales = selectedLocales;
  }
}

export { ShopModel as Shop };
