export class ContactModel {
  constructor(
    public id: number,
    public name: string,
    public name2: string | undefined,
    public street: string,
    public city: string,
    public zip: string,
    public country: string,
    public gssn: string,
    public vatId: string,
    public mail: string,
    public phone: string,
    public requestId: string,
  ) { }
}
