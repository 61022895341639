/**
 * History statuses.
 */
var HistoryStatusEnum;
(function (HistoryStatusEnum) {
    HistoryStatusEnum["Created"] = "CREATED";
    HistoryStatusEnum["Updated"] = "UPDATED";
})(HistoryStatusEnum || (HistoryStatusEnum = {}));
/**
 * Depth of shop levels.
 */
var ShopLevelEnum;
(function (ShopLevelEnum) {
    ShopLevelEnum[ShopLevelEnum["VS1"] = 1] = "VS1";
    ShopLevelEnum[ShopLevelEnum["VS2"] = 2] = "VS2";
    ShopLevelEnum[ShopLevelEnum["VS3"] = 3] = "VS3";
})(ShopLevelEnum || (ShopLevelEnum = {}));
/**
 * Shop component names.
 */
var ShopComponentNameEnum;
(function (ShopComponentNameEnum) {
    ShopComponentNameEnum["PrivacyPolicyUrl"] = "PRIVACY_POLICY_URL";
    ShopComponentNameEnum["ProviderInformationUrl"] = "PROVIDER_INFORMATION_URL";
})(ShopComponentNameEnum || (ShopComponentNameEnum = {}));
export { HistoryStatusEnum as HistoryStatus };
export { ShopLevelEnum as ShopLevel };
export { ShopComponentNameEnum as ShopComponentName };
