/**
 * Shop-specific rights.
 */
var RoleRightEnum;
(function (RoleRightEnum) {
    RoleRightEnum["ShopCreate"] = "SHOP_CREATE";
    RoleRightEnum["ShopRead"] = "SHOP_READ";
    RoleRightEnum["ShopUpdate"] = "SHOP_UPDATE";
    RoleRightEnum["ShopDelete"] = "SHOP_DELETE";
    RoleRightEnum["ShopProductCreate"] = "SHOP_PRODUCT_CREATE";
    RoleRightEnum["ShopProductRead"] = "SHOP_PRODUCT_READ";
    RoleRightEnum["ShopProductUpdate"] = "SHOP_PRODUCT_UPDATE";
    RoleRightEnum["ShopProductDelete"] = "SHOP_PRODUCT_DELETE";
    RoleRightEnum["ShopProductShareCreate"] = "SHOP_PRODUCT_SHARE_CREATE";
    RoleRightEnum["ShopProductShareRead"] = "SHOP_PRODUCT_SHARE_READ";
    RoleRightEnum["ShopProductShareUpdate"] = "SHOP_PRODUCT_SHARE_UPDATE";
    RoleRightEnum["ShopProductShareDelete"] = "SHOP_PRODUCT_SHARE_DELETE";
    RoleRightEnum["ShopProductFromSharedCreate"] = "SHOP_PRODUCT_FROM_SHARED_CREATE";
    RoleRightEnum["ShopOrderCreate"] = "SHOP_ORDER_CREATE";
    RoleRightEnum["ShopOrderRead"] = "SHOP_ORDER_READ";
    RoleRightEnum["ShopOrderUpdate"] = "SHOP_ORDER_UPDATE";
    RoleRightEnum["ShopOrderDelete"] = "SHOP_ORDER_DELETE";
    RoleRightEnum["ShopOrderExport"] = "SHOP_ORDER_EXPORT";
    RoleRightEnum["ShopOrderBatchCreate"] = "SHOP_ORDER_BATCH_CREATE";
    RoleRightEnum["ShopFileCreate"] = "SHOP_FILE_CREATE";
    RoleRightEnum["ShopFileRead"] = "SHOP_FILE_READ";
    RoleRightEnum["ShopFileUpdate"] = "SHOP_FILE_UPDATE";
    RoleRightEnum["ShopFileDelete"] = "SHOP_FILE_DELETE";
})(RoleRightEnum || (RoleRightEnum = {}));
export { RoleRightEnum as RoleRight };
